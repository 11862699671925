export const mainSubjectOptions = [
    "A medieval knight in shining armor",
    "A futuristic cityscape",
    "A forest elf conjuring magic",
    "An astronaut floating in space",
    "A pirate ship sailing the high seas",
    "A dragon perched on a mountain peak",
    "A bustling marketplace with exotic goods",
    "A magical library filled with glowing books",
    "A steampunk-inspired airship",
    "A serene Japanese garden with koi ponds",
    "A robotic guardian protecting a crystal temple",
    "A phoenix rising from the ashes",
    "A castle built on a floating island",
    "A giant clockwork robot in a steam-filled factory",
    "A herd of wild horses running through a desert storm",
    "A cybernetic warrior battling in a neon-lit arena",
    "A glowing alien artifact hidden in a dense jungle",
    "A family of polar bears on an icy tundra",
    "A mermaid exploring a sunken shipwreck",
    "A spaceship docking at an intergalactic space station",
    "A fairy village hidden within a massive tree",
    "A haunted mansion atop a misty hill",
    "A Viking longship navigating through icy waters",
    "A gladiator in an ancient Roman coliseum",
    "A samurai meditating under a cherry blossom tree",
    "A giant sea monster emerging from the ocean depths",
    "A wizard casting spells in a stormy battlefield",
    "A futuristic robot chef preparing a gourmet meal",
    "A caravan crossing the dunes of an endless desert",
    "A glowing crystal cavern filled with ancient relics",
    "A cyberpunk hacker working in a neon-lit hideout",
    "A peaceful village nestled in the shadow of a volcano",
    "A majestic eagle soaring over a mountain range",
    "A time traveler exploring a bustling 1920s street",
    "A group of adventurers discovering a lost city",
  ];

 export const actionSceneOptions = [
    "standing triumphantly",
    "running through a neon-lit street",
    "looking pensively at the horizon",
    "engaged in a fierce battle",
    "exploring ancient ruins",
    "floating peacefully in zero gravity",
    "gathering herbs in a mystical forest",
    "celebrating under fireworks",
    "playing a musical instrument in a tranquil setting",
    "casting a powerful spell with glowing hands",
    "riding a galloping horse across an open field",
    "diving into the depths of a crystal-clear ocean",
    "soaring through the sky with mechanical wings",
    "climbing a treacherous mountain path",
    "facing a fearsome dragon with sword in hand",
    "walking cautiously through a haunted forest",
    "discovering a hidden treasure chest in a cave",
    "leading an army into battle under a stormy sky",
    "painting a masterpiece in an art studio",
    "meditating on a mountaintop surrounded by clouds",
    "rescuing a trapped animal from a raging river",
    "dancing gracefully under a starry sky",
    "conducting a scientific experiment in a high-tech lab",
    "forging a weapon in a blazing forge",
    "sharing a heartfelt moment with a loved one",
    "performing a daring stunt on a motorcycle",
    "standing guard at the gates of a fortified castle",
    "writing in a journal while sitting by a campfire",
    "stargazing through a telescope on a moonlit night",
    "defending a village from an invading force",
    "navigating a small boat through turbulent waters",
    "repairing a spaceship in the middle of a meteor shower",
    "baking bread in a cozy kitchen",
    "tending to a garden filled with exotic plants",
    "uncovering ancient hieroglyphs in a desert tomb",
  ];

  export const shotTypeOptions = [
    "Extreme Wide Shot (EWS)",
    "Wide Shot (WS)",
    "Medium Wide Shot (MWS)",
    "Medium Shot (MS)",
    "Medium Close-Up (MCU)",
    "Close-Up (CU)",
    "Extreme Close-Up (ECU)",
    "Two-Shot",
    "Over-the-Shoulder (OTS)",
    "Point of View (POV)",
    "Dutch Angle/Tilt",
    "Tracking Shot",
    "Crane Shot",
    "High Angle Shot",
    "Low Angle Shot",
    "Bird's Eye View",
    "Worm's Eye View",
    "Close Tracking Shot",
    "Extreme Long Shot (ELS)",
    "Overhead Shot",
    "Insert Shot",
    "Cutaway",
    "Master Shot",
    "Establishing Shot",
    "Reaction Shot",
    "Split Screen Shot",
    "Close Group Shot",
    "Medium Group Shot",
    "Wide Group Shot",
    "Over-the-Head Shot",
    "Extreme Over-the-Shoulder",
    "Follow Shot",
    "Profile Shot",
    "Panoramic Shot",
    "Time-Lapse Shot",
    "Tilt Shot",
    "Zoom Shot",
    "Rack Focus",
    "Static Shot",
    "Handheld Shot",
    "Dolly Shot",
    "Steadicam Shot",
    "Over-the-Table Shot",
    "Medium Over-the-Shoulder Shot",
  ];

  export const cameraAngleOptions = [
    "Eye Level",
    "High Angle",
    "Low Angle",
    "Bird's Eye View",
    "Worm's Eye View",
    "Over-the-Shoulder",
    "Point of View (POV)",
    "Close-Up Perspective",
    "Extreme Close-Up Perspective",
    "Tilted/Dutch Angle",
    "Overhead Perspective",
    "Tracking Perspective",
    "Profile View",
    "Reverse Angle",
    "Oblique Angle",
    "Wide Perspective",
    "Extreme Wide Perspective",
    "Two-Point Perspective",
    "Three-Point Perspective",
    "Back-to-Front View",
    "Foreground-Focused Angle",
    "Rear View",
    "Reflection Angle",
    "Over-the-Head Angle",
    "Extreme Low Angle",
    "Extreme High Angle",
    "Dynamic Angle",
    "Steady Perspective",
    "Motion Blur Perspective",
    "Zoomed-In Perspective",
    "Zoomed-Out Perspective",
    "Subjective Perspective",
    "Objective Perspective",
  ];

  export const styleOptions = [
    "Photorealistic",
    "Sketch",
    "3D Render",
    "Illustration",
    "Digital Painting",
    "Oil Painting",
    "Watercolor",
    "Charcoal Drawing",
    "Pencil Sketch",
    "Ink Drawing",
    "Vector Art",
    "Collage",
    "Abstract",
    "Impressionist",
    "Cubism",
    "Surrealist",
    "Realism",
    "Fantasy Art",
    "Sci-Fi Art",
    "Steampunk",
    "Cyberpunk",
    "Pop Art",
    "Anime/Manga",
    "Low-Poly 3D",
    "Pixel Art",
    "Claymation",
    "Mixed Media",
    "Chalk Art",
    "High Fantasy",
    "Minimalist",
    "Flat Design",
    "Photographic Collage",
    "Isometric Art",
    "Gothic Style",
    "Baroque Style",
    "Renaissance Style",
    "Neon Art",
    "Comic Book Style",
    "Hyperrealistic",
    "Matte Painting",
    "Procedural Generation",
    "Stencil Art",
    "Street Art",
  ];

  export const lightingOptions = [
    "Golden Hour",
    "Moody Shadows",
    "Bright & Airy",
    "Soft Diffused Light",
    "Cinematic Lighting",
    "High Contrast",
    "Low Key Lighting",
    "High Key Lighting",
    "Ambient Glow",
    "Backlighting",
    "Side Lighting",
    "Front Lighting",
    "Spotlight",
    "Candlelight",
    "Moonlit Night",
    "Stormy and Overcast",
    "Rainbow Prism Effect",
    "Dramatic Shadows",
    "Neon Glow",
    "Sunlight Filtering Through Trees",
    "Underwater Glow",
    "Firelight",
    "Foggy and Mysterious",
    "Ethereal Light",
    "Twilight Glow",
    "Dappled Light",
    "Warm and Inviting",
    "Cold and Harsh",
    "Starry Sky Lighting",
    "Volumetric Lighting",
    "Silhouette Lighting",
    "Gloomy Atmosphere",
    "Desert Sunlight",
    "Rain-Soaked Reflections",
    "Vibrant Party Lighting",
    "Glowing Bioluminescence",
    "Industrial Lighting",
    "Romantic Candlelit",
    "Morning Light",
    "Evening Light",
    "Artificial Fluorescent Light",
  ];

  export const colorPaletteOptions = [
    "Warm Earth Tones",
    "Cool Blues and Grays",
    "Vibrant and High-Contrast",
    "Pastel Shades",
    "Dark and Moody",
    "Monochromatic",
    "Black and White",
    "Sepia Tone",
    "Neon Colors",
    "Muted Tones",
    "Bold Primaries",
    "Gradient Rainbow",
    "Desaturated Colors",
    "Rustic Autumn",
    "Oceanic Palette",
    "Sunset Warmth",
    "Ice and Frost",
    "Golden and Luxurious",
    "Tropical Brights",
    "Forest Greens",
    "Dusty Desert Palette",
    "Electric Colors",
    "Romantic Reds and Pinks",
    "Spring Freshness",
    "Vintage Retro Palette",
    "Crisp Winter Whites",
    "Industrial Metallics",
    "Dreamy Pastels",
    "Cyberpunk Neon",
    "Subtle Greyscale",
    "Jewel Tones",
    "Soft and Ethereal",
    "Energetic Citrus",
    "Mystic Purples",
    "Urban Neutral Tones",
    "Classic Black and Gold",
  ];

  export const environmentOptions = [
    "Fantasy Landscape",
    "Urban Cityscape",
    "Natural Forest",
    "Desert Dunes",
    "Snowy Mountain Peaks",
    "Underwater Reef",
    "Lush Jungle",
    "Outer Space",
    "Futuristic City",
    "Medieval Village",
    "Dark Cavern",
    "Enchanted Forest",
    "Tropical Island",
    "Ruined Temple",
    "Industrial Factory",
    "Apocalyptic Wasteland",
    "Secluded Beach",
    "Rolling Hills",
    "Bustling Marketplace",
    "Ancient Ruins",
    "Stormy Ocean",
    "Moonlit Night",
    "Countryside Farm",
    "Lavender Fields",
    "Underground Bunker",
    "Crystal Cave",
    "Sci-Fi Laboratory",
    "Sunny Meadow",
    "Haunted House",
    "Frozen Tundra",
    "Volcanic Landscape",
    "Open Prairie",
    "Suburban Neighborhood",
    "Ancient Library",
    "Majestic Waterfall",
    "High-Tech Command Center",
    "Floating Sky Island",
    "Dense Foggy Swamp",
    "Ethereal Cloudscape",
  ];