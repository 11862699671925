export const stylePrefixOptions = [
  "Disney Pixar Style",
  "Renaissance",
  "Baroque",
  "Rococo",
  "Impressionism",
  "Post-Impressionism",
  "Expressionism",
  "Cubism",
  "Surrealism",
  "Abstract Art",
  "Pop Art",
  "Art Deco",
  "Art Nouveau",
  "Minimalism",
  "Futurism",
  "Realism",
  "Romanticism",
  "Symbolism",
  "3D Render",
  "Anime/Manga Style",
  "Pixel Art",
  "Vector Art",
  "Low Poly",
  "Isometric Art",
  "Matte Painting",
  "Digital Painting",
  "Concept Art",
  "Stylized Art",
  "Cel Shading",
  "Photorealism",
  "Hyperrealism",
  "Game Art",
  "Charcoal",
  "Pencil Sketch",
  "Watercolor",
  "Oil Painting",
  "Acrylic Painting",
  "Ink Wash",
  "Pastels",
  "Colored Pencil",
  "Gouache",
  "Collage",
  "Fresco",
  "Tempera",
  "Mixed Media",
  "Pointillism",
  "Linocut",
  "Flat Design",
  "Bauhaus Style",
  "Geometric Design",
  "Grunge Style",
  "Vaporwave",
  "Cyberpunk",
  "Steampunk",
  "Retro/Vintage Style",
  "Mid-Century Modern",
  "Futuristic Design",
  "Typography Art",
  "Infographic Style",
  "Black and White Photography",
  "Sepia Tone",
  "High Dynamic Range",
  "Macro Photography Style",
  "Portrait Photography",
  "Landscape Photography",
  "Long Exposure",
  "Tilt-Shift Photography",
  "Double Exposure",
  "Dark Fantasy",
  "High Fantasy",
  "Gothic Style",
  "Cosmic Horror",
  "Steampunk Fantasy",
  "Cyberpunk Futurism",
  "Biopunk",
  "Dieselpunk",
  "Post-Apocalyptic Style",
  "Ukiyo-e",
  "Byzantine Art",
  "Egyptian Hieroglyphs",
  "Mayan Art",
  "Islamic Geometric Patterns",
  "Tribal Art",
  "Indigenous Art Styles",
  "African Mask Design",
  "Glitch Art",
  "Data-Moshing",
  "Chromatic Aberration Effects",
  "Drip Painting",
  "Abstract Expressionism",
  "Fractal Art",
  "Gradient Mesh Art",
  "Comic Book Style",
  "Street Art/Graffiti",
  "Pop Surrealism",
  "Kawaii Style",
  "Fantasy Map Illustration",
  "Propaganda Poster Style",
  "Psychedelic Art",
  "Stippling",
  "Cross-Hatching",
  "Block Printing",
  "Lithography",
  "Paper Cut Art",
  "Mosaic Art",
  "Sgraffito",
  "Embroidery Art",
  "Stained Glass Design",
  "Ray Tracing",
  "Path Tracing",
  "Ambient Occlusion",
  "Procedural Texturing",
  "Physically-Based Rendering",
  "Wireframe Style",
  "Volumetric Lighting",
  "Neo-Expressionism",
  "Neo-Classicism",
  "Dadaism",
  "Constructivism",
  "Vorticism",
  "Photomontage",
  "Kinetic Art",
  "Street Photography",
  "Infrared Photography",
  "Solarized Photography",
  "Orphism",
  "Hard-Edge Painting",
  "Lyrical Abstraction",
  "Op Art",
  "Trompe-l'oeil",
  "Mannerism",
  "Fauvism",
  "Tenebrism",
  "Pre-Raphaelite Style",
  "Outsider Art",
  "Folk Art",
  "Tattoo Art",
  "Zentangle",
  "Manga Realism",
  "Mecha Art",
  "Ghibli-Inspired Style",
  "Lowbrow Art",
  "Glamour Portraiture",
  "Silhouette Art",
  "Chiaroscuro",
  "Sfumato",
  "Graffiti Lettering",
  "Decoupage",
  "Whimsical Art",
  "Abstract Geometric Art",
  "Minimal Line Art",
  "Mandala Art",
  "Sci-Fi Concept Art",
  "Heroic Fantasy Art",
  "Mythological Illustration",
  "Speculative Biology",
  "Retro-Futurism",
  "Doodle Art",
  "Sticker Art",
  "Tatami Patterns",
  "Ethnic Motif Design",
  "Organic Abstracts",
  "Architectural Illustration",
  "Blueprint Style",
  "Woodblock Printing",
];

export const whoOptions = [
  "Doctor",
  "Dancer",
  "Magician",
  "Clown",
  "Giraffe",
  "Parrot",
  "Polar Bear",
  "Dolphin",
  "Cherry Blossom Tree",
  "Sunflower",
  "Cactus",
  "Spaghetti",
  "Sushi",
  "Strawberry Cake",
  "Castle",
  "Skyscraper",
  "Wooden House",
  "Electric Guitar",
  "Telescope",
  "Hot Air Balloon",
  "Astronaut",
  "Nurse",
  "Explorer",
  "Artist",
  "Pirate",
  "Farmer",
  "Knight",
  "Archaeologist",
  "Chef",
  "Scientist",
  "Teacher",
  "Gardener",
  "Blacksmith",
  "Baker",
  "Musician",
  "Firefighter",
  "Police Officer",
  "Hiker",
  "Librarian",
  "Engineer",
  "Pilot",
  "Veterinarian",
  "Diver",
  "Mountain Climber",
  "Writer",
  "Photographer",
  "Inventor",
  "Carpenter",
  "Sculptor",
  "Robot",
  "Superhero",
  "Alien",
  "Elf",
  "Wizard",
  "Dragon",
  "Giant",
  "Mermaid",
  "King",
  "Queen",
  "Prince",
  "Princess",
  "Soldier",
  "Hunter",
  "Cowboy",
  "Detective",
  "Samurai",
  "Captain",
  "Merchant",
  "Spy",
  "Athlete",
  "Butler",
  "Maid",
  "Shaman",
  "Sorcerer",
  "Ninja",
  "Assassin",
  "Painter",
  "Sailor",
  "Monk",
  "Astronomer",
  "Journalist",
  "Weaver",
  "Miner",
  "Zookeeper",
  "Fisherman",
  "Barista",
  "Beekeeper",
  "Cowgirl",
  "Viking",
  "Swordsman",
  "Martial Artist",
  "Acrobat",
  "Gladiator",
  "Juggler",
  "Fortune Teller",
  "Illusionist",
  "Historian",
  "Botanist",
  "Physicist",
  "Environmentalist",
  "Paramedic",
  "Fire Dancer",
  "Robotic Engineer",
  "Climber",
  "Meteorologist",
  "Tailor",
  "Shepherd",
  "Candle Maker",
  "Geologist",
  "Street Performer",

  // Add more items as needed...
];

export const whatOptions = [
  "A young female doctor wearing a white coat",
  "A colorful parrot in flight, against a blue sky",
  "A massive redwood tree surrounded by a dense forest",
  "A fresh sushi platter with salmon, tuna, and eel",
  "An ancient stone bridge spanning a clear river",
  "A young artist sketching in a notebook by a riverside",
  "A chef carefully plating a gourmet dish in a kitchen",
  "A scientist examining a petri dish under a microscope",
  "A child flying a kite on a windy hilltop",
  "A musician playing a violin in a candlelit concert hall",
  "A traveler reading a map at a bustling train station",
  "A mountain climber reaching the summit during sunrise",
  "A swimmer diving into a crystal-clear lake",
  "A farmer harvesting crops in a golden wheat field",
  "A librarian shelving books in a quiet library",
  "A family having a picnic under a blooming cherry blossom tree",
  "A knight kneeling before a royal throne",
  "A firefighter rescuing a kitten from a tree",
  "A surfer riding a massive wave at sunset",
  "A baker decorating a multi-tiered wedding cake",
  "A teacher writing equations on a chalkboard",
  "A hiker setting up a tent in a dense forest",
  "A couple dancing under a string of fairy lights",
  "A dog chasing a ball in a park full of fallen leaves",
  "A gardener planting flowers in a backyard garden",
  "A fisherman casting a line into a tranquil river",
  "A sculptor carving a statue from marble",
  "A yoga instructor meditating on a beach at sunrise",
  "A mechanic repairing an old vintage car",
  "A pilot preparing a plane for takeoff",
  "A young girl painting a colorful mural on a wall",
  "A group of friends roasting marshmallows around a campfire",
  "A robot delivering packages in a futuristic city",
  "A photographer capturing wildlife in a jungle",
  "A diver exploring a coral reef teeming with fish",
  "A dancer performing on a grand stage with dramatic lighting",
  "A child building a sandcastle on a sunny beach",
  "A writer typing on a vintage typewriter in a cozy study",
  "A group of athletes running a marathon in the city",
  "A singer performing on an open-air stage under the stars",
  "A cat lounging on a windowsill in a sunny room",
  "A painter creating a landscape masterpiece in an art studio",
  "A wizard casting spells in a mysterious forest",
  "A family decorating a Christmas tree in their living room",
  "A young boy discovering a treasure chest in a cave",
  "A blacksmith forging a sword in a glowing forge",
  "A cyclist racing down a winding mountain trail",
  "A spaceship landing on a distant alien planet",
  "A superhero saving people from a collapsing building",
  "A bride and groom walking down the aisle at an outdoor wedding",
  "A street performer juggling flaming torches",
  "A panda eating bamboo in a tranquil bamboo grove",
  "A researcher taking notes in a remote Arctic station",
  "A robot assembling a futuristic device in a high-tech lab",
  "A knight defending a castle during a fierce battle",
  "A detective investigating a crime scene with a magnifying glass",
  "A chef flipping pancakes in a bustling breakfast diner",
  "A wizard summoning a fireball in a dark dungeon",
  "A surfer gliding through a giant wave at sunrise",
  "A scientist conducting an experiment in a space station",
  "A farmer herding sheep across a grassy meadow",
  "A musician composing a symphony on a grand piano",
  "A painter creating a mural on a building wall",
  "A street performer juggling flaming torches in a square",
  "A child building a snowman in a snowy backyard",
  "A dancer performing an intricate routine on a stage",
  "A librarian organizing ancient scrolls in a vast library",
  "A firefighter putting out a blaze in a historic building",
  "A traveler exploring the ruins of an ancient temple",
  "A group of friends stargazing in the countryside",
  "A mechanic fixing a motorcycle in a cluttered garage",
  "A fisherman catching a large trout in a mountain stream",
  "A boy flying a kite on a breezy hilltop",
  "A hiker resting by a campfire in the woods",
  "A young girl planting flowers in a garden",
  "A photographer capturing a sunset on a tropical beach",
  "A robot cleaning the floor of a futuristic home",
  "A dog running through a field of tall grass",
  "A couple walking hand in hand along the shoreline",
  "A scientist presenting findings at a conference",
  "A girl blowing bubbles in a sunny backyard",
  "A farmer riding a tractor through a wheat field",
  "A circus performer balancing on a tightrope",
  "A teacher helping students in a classroom",
  "A mermaid swimming near a coral reef",
  "A group of children roasting marshmallows over a campfire",
  "A diver swimming alongside a school of fish",
  "A blacksmith hammering metal in a glowing forge",
  "A writer drafting a novel on a laptop in a coffee shop",
  "A cat lounging on a sunlit windowsill",
  "A family hiking through a dense forest trail",
  "A singer performing a solo at an opera house",
  "A sculptor chiseling a statue in an outdoor studio",
  "A kid dressed as a superhero saving imaginary people",
  "A cyclist racing down a mountain trail",
  "A warrior defending a village from an attack",
  "A magician performing a disappearing act on stage",
  "A gardener trimming a hedge in a botanical garden",
  "A space explorer floating outside a spaceship",

  // Add more items as needed...
];

export const whereOptions = [
  "Mountain Stream",
  "Rainforest",
  "Desert",
  "Seashore",
  "Alpine Snowfield",
  "Busy Business District",
  "Peaceful Suburb",
  "Modern Technology Park",
  "Cozy Living Room",
  "Luxurious Restaurant",
  "Intense Battlefield",
  "Ancient Palace",
  "Futuristic Technology City",
  "Magical Forest",
  "Western Cowboy Town",
  "A dense jungle with vibrant green foliage",
  "A tranquil beach with white sand and clear blue water",
  "A bustling city street lined with neon signs",
  "A peaceful meadow filled with wildflowers",
  "A snowy mountain peak under a bright blue sky",
  "A dark forest illuminated by glowing fireflies",
  "An abandoned castle covered in ivy",
  "A futuristic cityscape with towering skyscrapers",
  "A cozy cabin in the woods during a snowstorm",
  "An ancient temple hidden in the jungle",
  "A vibrant coral reef under crystal-clear water",
  "A quiet village surrounded by rolling hills",
  "A massive library filled with ancient books",
  "A sunny orchard with rows of apple trees",
  "A modern art museum with abstract sculptures",
  "A desert oasis with a sparkling waterhole",
  "A rustic farm with a red barn and grazing animals",
  "A crowded marketplace filled with colorful stalls",
  "A medieval village with cobblestone streets",
  "A high-tech laboratory with glowing screens",
  "An underwater cave lit by glowing bioluminescence",
  "A serene Japanese garden with koi ponds and pagodas",
  "A bustling train station in a historic city",
  "A misty swamp with twisted trees and murky water",
  "A vast desert with towering sand dunes",
  "A magical forest with glowing mushrooms",
  "A luxurious palace with golden chandeliers",
  "A peaceful lake surrounded by towering mountains",
  "A volcanic landscape with rivers of lava",
  "An arctic tundra with endless snow and ice",
  "A sunflower field stretching to the horizon",
  "A pirate ship anchored near a hidden cove",
  "A space station orbiting a distant planet",
  "A crowded carnival with vibrant lights and rides",
  "A grand cathedral with towering stained glass windows",
  "A rainforest canopy with swinging vines and wildlife",
  "A tranquil monastery on a remote mountaintop",
  "A train winding through a snowy mountain pass",
  "A lighthouse perched on a rocky cliff by the sea",
  "A bustling harbor with ships and cargo crates",
  "A serene valley with a crystal-clear river",
  "A fantasy marketplace with exotic goods and creatures",
  "A neon-lit alleyway in a cyberpunk city",
  "A historical battlefield covered in mist",
  "A space observatory with a massive telescope",
  "A quaint café on a Parisian street corner",
  "A vibrant flower garden in full bloom",
  "A mystical cave filled with glowing crystals",

  // Add more items as needed...
];

export const whenOptions = [
  "Dawn",
  "Noon",
  "6 PM",
  "Midnight",
  "Morning",
  "Afternoon",
  "Evening",
  "Night",
  "Renaissance",
  "Industrial Revolution",
  "Middle Ages",
  "Early Spring",
  "Midsummer",
  "Autumn Equinox",
  "Early Winter",
  "Sunrise",
  "Mid-morning",
  "Late afternoon",
  "Dusk",
  "Golden hour",
  "Blue hour",
  "During a thunderstorm",
  "In the middle of a snowstorm",
  "At the peak of summer",
  "On a crisp autumn morning",
  "During the winter solstice",
  "Under a full moon",
  "During a lunar eclipse",
  "On a foggy morning",
  "During a meteor shower",
  "On a cloudy day",
  "At a festival or celebration",
  "In the middle of the night",
  "During a solar eclipse",
  "At a New Year’s Eve party",
  "During a spring rain shower",
  "At a summer solstice celebration",
  "During harvest season",
  "At an outdoor music concert",
  "On a snowy Christmas evening",
  "During a bustling morning rush hour",
  "At a historical reenactment event",
  "On a tropical summer evening",
  "During a medieval fair",
  "At sunset on a desert plain",
  "During a grand parade",
  "At the crack of dawn",
  "During a peaceful twilight",
  "On a stormy afternoon",
  "At a fireworks show",
  "During a calm before a storm",
  "At a family reunion in summer",
  "During an important historical event",
  "On the eve of a great battle",
  "In a futuristic utopian society",
  "During the Renaissance",
  "At a midnight bonfire",
  "During a lively carnival at night",
  "On an early spring day",
  "During an intergalactic celebration",
  "At a dystopian future event",
  "On a serene autumn evening",

  // Add more items as needed...
];

export const whyOptions = [
  "Reading a book",
  "Playing the piano",
  "Painting a landscape",
  "Drinking coffee",
  "Writing in a journal",
  "Sketching a portrait",
  "Cooking a meal",
  "Gardening in the backyard",
  "Jogging in the park",
  "Practicing yoga",
  "Meditating on a hilltop",
  "Dancing under the moonlight",
  "Singing in the shower",
  "Feeding the pets",
  "Walking the dog",
  "Calling a friend",
  "Texting on a phone",
  "Taking a selfie",
  "Building a sandcastle",
  "Fishing by the river",
  "Riding a bicycle",
  "Flying a kite",
  "Swimming in the ocean",
  "Camping under the stars",
  "Having a picnic",
  "Fighting in a duel",
  "Sparring in a dojo",
  "Racing a car",
  "Driving a motorcycle",
  "Flying a drone",
  "Throwing a frisbee",
  "Playing catch",
  "Climbing a tree",
  "Hiking up a mountain",
  "Exploring a cave",
  "Searching for treasure",
  "Watching the sunset",
  "Stargazing on a clear night",
  "Reading a bedtime story",
  "Knitting a scarf",
  "Sewing a dress",
  "Fixing a car engine",
  "Building a model airplane",
  "Assembling furniture",
  "Playing chess",
  "Building a puzzle",
  "Hosting a dinner party",
  "Sharing a toast",
  "Celebrating a birthday",
  "Crying with joy",
  "Laughing hysterically",
  "Looking into the distance",
  "Smiling softly",
  "Being surprised by a gift",
  "Holding hands with a loved one",
  "Hugging a friend",
  "Arguing in a heated debate",
  "Comforting someone who is sad",
  "Teaching a class",
  "Helping a child with homework",
  "Playing with toys",
  "Throwing a tantrum",
  "Practicing a speech",
  "Performing on stage",
  "Listening to a podcast",
  "Watching a movie",
  "Shopping in a market",
  "Carrying groceries",
  "Painting a mural",
  "Learning to play an instrument",
  "Surfing on a wave",
  "Scuba diving in a coral reef",
  "Baking cookies",
  "Tasting wine",
  "Frying pancakes",
  "Tending a campfire",
  "Cutting vegetables",
  "Sharpening a knife",
  "Lighting candles",
  "Watering plants",
  "Harvesting crops",
  "Feeding chickens",
  "Shearing sheep",
  "Mowing the lawn",
  "Shoveling snow",
  "Raking leaves",
  "Throwing confetti",
  "Blowing bubbles",
  "Jumping in puddles",
  "Swinging on a swing set",
  "Sliding down a waterslide",
  "Diving off a diving board",
  "Playing hopscotch",
  "Skipping rope",
  "Walking barefoot on the grass",
  "Collecting seashells",
  "Drawing on the sidewalk with chalk",
  "Flying paper airplanes",

  // Add more items as needed...
];

export const howOptions = [
  "Golden ratio composition",
  "Diagonal composition",
  "Bird's-eye view",
  "Looking up",
  "Eye level",
  "Impressionism",
  "Realism",
  "Cyberpunk",
  "Cool tones",
  "Warm tones",
  "High-saturation tones",
  "Soft light",
  "Strong shadows",
  "Gentle morning light",
  "Smooth",
  "Rough",
  "Furry",
  "Silky",
  "Decorations",
  "Background elements",
  "Exquisite patterns",
  "Using the golden ratio for composition",
  "With a bird’s-eye view angle",
  "Through impressionist brush strokes",
  "In a high-contrast lighting setup",
  "By using cool blue tones",
  "With soft focus on the foreground",
  "Using dynamic diagonal lines",
  "With an abstract cubist style",
  "Through a surrealist lens",
  "With glowing ambient lighting",
  "Using a soft pastel palette",
  "In a minimalist style",
  "Using warm and inviting tones",
  "Through a highly textured surface",
  "With dramatic shadows",
  "Using smooth and glossy textures",
  "In a futuristic design",
  "With lens flare effects",
  "Through the use of bold color blocking",
  "In a monochromatic style",
  "With a glowing neon aesthetic",
  "In a fragmented collage composition",
  "Using an underwater perspective",
  "Through hand-drawn animation",
  "Using a surreal dreamlike filter",
  "In an impressionist movement style",
  "With exaggerated textures",
  "In a low-poly 3D style",
  "Through the use of ethereal lighting",
  "With shimmering and reflective surfaces",
  "Using bold geometric patterns",
  "Through a steampunk aesthetic",
  "In an industrial cyberpunk style",
  "With highly saturated colors",
  "Using a fisheye lens effect",
  "In a detailed hyperrealistic style",
  "With intricate ornamentation",
  "Using golden hour lighting",
  "Through the use of asymmetrical framing",
  "In a minimalist sci-fi aesthetic",
  "With surreal floating objects",
  "In a painterly illustration style",
  "Using dynamic action poses",
  "With a cinematic color grade",
  "Through layered textures",
  "With a glowing bioluminescent effect",
  "In a fragmented kaleidoscopic view",
  "Through sweeping aerial perspectives",

  // Add more items as needed...
];
