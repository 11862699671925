import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./ImagePromptGenerator.css"; // Use the existing CSS file
import {
  stylePrefixOptions,
  whoOptions,
  whatOptions,
  whereOptions,
  whenOptions,
  whyOptions,
  howOptions,
} from "./KlingOptions";

function KlingGenerator() {
  const [style, setStylePrefix] = useState("");
  const [who, setWho] = useState("");
  const [what, setWhat] = useState("");
  const [where, setWhere] = useState("");
  const [when, setWhen] = useState("");
  const [why, setWhy] = useState("");
  const [how, setHow] = useState("");
  const [generatedPrompt, setGeneratedPrompt] = useState("");
  const [imageFile, setImageFile] = useState(null);

  const [apiKey, setApiKey] = useState(localStorage.getItem("apiKey") || "");
const navigate = useNavigate();
  useEffect(() => {
    localStorage.setItem("apiKey", apiKey);
  }, [apiKey]);

  const randomItem = (array) => array[Math.floor(Math.random() * array.length)];

  const randomizeAll = () => {
    setStylePrefix(randomItem(stylePrefixOptions));
    setWho(randomItem(whoOptions));
    setWhat(randomItem(whatOptions));
    setWhere(randomItem(whereOptions));
    setWhen(randomItem(whenOptions));
    setWhy(randomItem(whyOptions));
    setHow(randomItem(howOptions));
  };

  const generatePrompt = async () => {
    if (!style && !who && !what && !where && !when && !why && !how) {
      alert("Please provide details for at least one section!");
      return;
    }

    if (!apiKey) {
      alert("Please provide your OpenAI API key.");
      return;
    }

    try {
      const response = await fetch("/api/klingGen", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          style,
          who,
          what,
          where,
          when,
          why,
          how,
          apiKey,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to generate prompt.");
      }

      const data = await response.json();
      setGeneratedPrompt(data.prompt);
    } catch (error) {
      console.error(error);
      alert("Failed to generate the prompt. Please try again.");
    }
  };

  const clearFields = () => {
    setStylePrefix("");
    setWho("");
    setWhat("");
    setWhere("");
    setWhen("");
    setWhy("");
    setHow("");
    setGeneratedPrompt("");
  };

  const uploadImageAndGeneratePrompt = async () => {
    if (!imageFile) {
      alert("Please upload an image before generating a prompt.");
      return;
    }

    const formData = new FormData();
    formData.append("image", imageFile);
    formData.append("apiKey", apiKey);

    console.log("Uploading image:", imageFile);

    try {
      const response = await fetch("/api/processImage", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) throw new Error("Failed to process the image.");
      const data = await response.json();
      setGeneratedPrompt(data.prompt);

      alert(`Image uploaded! Accessible at: ${data.imageUrl}`);
    } catch (error) {
      console.error("Error processing image:", error);
      alert("There was an error processing the image. Please try again.");
    }
  };

  return (
    <div className="app-container2">
      <button className="roll-button2" onClick={() => navigate("/options")}>
          Go Back
        </button>
      <div className="api-key-section">
        <label>OpenAI API Key:</label>
        <input
          type="password"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
          placeholder="Enter your API key"
          className="api-key-input"
        />
      </div>

      <h2>Kling Image Prompt Generator</h2>
      <div className="grid-container2">
        <div className="card2">
          <label>Style Prefix:</label>
          <select
            value={style}
            onChange={(e) => setStylePrefix(e.target.value)}
          >
            <option value="">Select...</option>
            {stylePrefixOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <textarea
            value={style}
            onChange={(e) => setStylePrefix(e.target.value)}
            placeholder="Add a style prefix..."
          />
          <button
            onClick={async () => {
              try {
                const response = await fetch("/api/klingGenRandom", {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({ category: "Style Prefix", apiKey }),
                });
                if (!response.ok)
                  throw new Error("Failed to generate random Style Prefix.");
                const data = await response.json();
                setStylePrefix(data.randomOption);
              } catch (error) {
                alert("Failed to generate random option. Please try again.");
              }
            }}
          >
            Random Style Prefix
          </button>
        </div>
        <div className="card2">
          <label>Who:</label>
          <select value={who} onChange={(e) => setWho(e.target.value)}>
            <option value="">Select...</option>
            {whoOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <textarea
            value={who}
            onChange={(e) => setWho(e.target.value)}
            placeholder="Describe who is in the scene..."
          />
          <button
            onClick={async () => {
              try {
                const response = await fetch("/api/klingGenRandom", {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({ category: "Who", apiKey }),
                });
                if (!response.ok)
                  throw new Error("Failed to generate random Who option.");
                const data = await response.json();
                setWho(data.randomOption); // Update the 'Who' text field
              } catch (error) {
                alert("Failed to generate random option. Please try again.");
              }
            }}
          >
            (GPT) Random Who
          </button>
        </div>
        <div className="card2">
          <label>What:</label>
          <select value={what} onChange={(e) => setWhat(e.target.value)}>
            <option value="">Select...</option>
            {whatOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <textarea
            value={what}
            onChange={(e) => setWhat(e.target.value)}
            placeholder="Describe what is happening..."
          />
          <button
            onClick={async () => {
              try {
                const response = await fetch("/api/klingGenRandom", {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({ category: "What", apiKey }),
                });
                if (!response.ok)
                  throw new Error("Failed to generate random what option.");
                const data = await response.json();
                setWhat(data.randomOption); // Update the 'Who' text field
              } catch (error) {
                alert("Failed to generate random option. Please try again.");
              }
            }}
          >
            (GPT) Random What
          </button>
        </div>
        <div className="card2">
          <label>Where:</label>
          <select value={where} onChange={(e) => setWhere(e.target.value)}>
            <option value="">Select...</option>
            {whereOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <textarea
            value={where}
            onChange={(e) => setWhere(e.target.value)}
            placeholder="Describe where the scene takes place..."
          />
          <button
            onClick={async () => {
              try {
                const response = await fetch("/api/klingGenRandom", {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({ category: "Where", apiKey }),
                });
                if (!response.ok)
                  throw new Error("Failed to generate random where option.");
                const data = await response.json();
                setWhere(data.randomOption); // Update the 'Who' text field
              } catch (error) {
                alert("Failed to generate random option. Please try again.");
              }
            }}
          >
            (GPT) Random Where
          </button>
        </div>
        <div className="card2">
          <label>When:</label>
          <select value={when} onChange={(e) => setWhen(e.target.value)}>
            <option value="">Select...</option>
            {whenOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <textarea
            value={when}
            onChange={(e) => setWhen(e.target.value)}
            placeholder="Describe when the scene happens..."
          />
          <button
            onClick={async () => {
              try {
                const response = await fetch("/api/klingGenRandom", {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({ category: "When", apiKey }),
                });
                if (!response.ok)
                  throw new Error("Failed to generate random when option.");
                const data = await response.json();
                setWhen(data.randomOption); // Update the 'Who' text field
              } catch (error) {
                alert("Failed to generate random option. Please try again.");
              }
            }}
          >
            (GPT) Random When
          </button>
        </div>
        <div className="card2">
          <label>Why:</label>
          <select value={why} onChange={(e) => setWhy(e.target.value)}>
            <option value="">Select...</option>
            {whyOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <textarea
            value={why}
            onChange={(e) => setWhy(e.target.value)}
            placeholder="Describe why the scene is happening..."
          />
          <button
            onClick={async () => {
              try {
                const response = await fetch("/api/klingGenRandom", {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({ category: "Why", apiKey }),
                });
                if (!response.ok)
                  throw new Error("Failed to generate random Why option.");
                const data = await response.json();
                setWhy(data.randomOption); // Update the 'Who' text field
              } catch (error) {
                alert("Failed to generate random option. Please try again.");
              }
            }}
          >
            (GPT) Random Why
          </button>
        </div>
        <div className="card2">
          <label>How:</label>
          <select value={how} onChange={(e) => setHow(e.target.value)}>
            <option value="">Select...</option>
            {howOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <textarea
            value={how}
            onChange={(e) => setHow(e.target.value)}
            placeholder="Describe how the scene unfolds..."
          />
          <button
            onClick={async () => {
              try {
                const response = await fetch("/api/klingGenRandom", {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({ category: "How", apiKey }),
                });
                if (!response.ok)
                  throw new Error("Failed to generate random How option.");
                const data = await response.json();
                setHow(data.randomOption); // Update the 'Who' text field
              } catch (error) {
                alert("Failed to generate random option. Please try again.");
              }
            }}
          >
            (GPT) Random How
          </button>
        </div>
      </div>
      <button onClick={randomizeAll}>Randomize Selections</button>
      <button onClick={generatePrompt}>Generate Prompt</button>
      <button onClick={clearFields} style={{ marginLeft: "10px" }}>
        Clear Fields
      </button>

      {generatedPrompt && (
  <div className="generated-output2">
    <h3>Generated Prompt:</h3>
    <textarea
      value={generatedPrompt}
      onChange={(e) => setGeneratedPrompt(e.target.value)}
      className="editable-prompt"
      rows={6}
      placeholder="Edit the generated prompt here..."
    />
    <button
      onClick={() => navigator.clipboard.writeText(generatedPrompt)}
    >
      Copy Prompt
    </button>
  </div>
)}


      <h2>Kling Video Prompt Generator</h2>
      <div className="card2">
        <label htmlFor="image-upload">Upload an Image:</label>
        <input
          type="file"
          id="image-upload"
          accept="image/*"
          onChange={(e) => {
            const file = e.target.files[0];
            setImageFile(file);
            if (file) {
              const reader = new FileReader();
              reader.onload = (event) => {
                document.getElementById("image-preview").src =
                  event.target.result;
              };
              reader.readAsDataURL(file);
            }
          }}
        />
        <img
          id="image-preview"
          alt="Preview"
          style={{ display: imageFile ? "block" : "none", maxWidth: "25%" }}
        />
        <button
          onClick={async () => {
            if (!imageFile) {
              alert("Please upload an image before generating a prompt.");
              return;
            }

            const formData = new FormData();
            formData.append("image", imageFile);
            formData.append("apiKey", apiKey);

            try {
              const response = await fetch("/api/processImage", {
                method: "POST",
                body: formData,
              });

              if (!response.ok) throw new Error("Failed to process the image.");
              const data = await response.json();
              setGeneratedPrompt(data.prompt);

              alert(`Image uploaded! Accessible at: ${data.imageUrl}`);
            } catch (error) {
              console.error("Error processing image:", error);
              alert(
                "There was an error processing the image. Please try again."
              );
            }
          }}
        >
          Generate Prompt from Image
        </button>
      </div>
    </div>
  );
}

export default KlingGenerator;
