import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ImagePromptGenerator.css";
import {
  mainSubjectOptions,
  actionSceneOptions,
  shotTypeOptions,
  cameraAngleOptions,
  styleOptions,
  lightingOptions,
  colorPaletteOptions,
  environmentOptions,
} from "./ImagePromptOptions";


function ImagePromptGenerator() {
  const randomItem = (array) => array[Math.floor(Math.random() * array.length)];

  const [mainSubject, setMainSubject] = useState("");
  const [mainSubjectDetails, setMainSubjectDetails] = useState("");
  const [actionScene, setActionScene] = useState("");
  const [actionSceneDetails, setActionSceneDetails] = useState("");
  const [shotType, setShotType] = useState("");
  const [shotTypeDetails, setShotTypeDetails] = useState("");
  const [cameraAngle, setCameraAngle] = useState("");
  const [cameraAngleDetails, setCameraAngleDetails] = useState("");
  const [style, setStyle] = useState("");
  const [styleDetails, setStyleDetails] = useState("");
  const [lighting, setLighting] = useState("");
  const [lightingDetails, setLightingDetails] = useState("");
  const [colorPalette, setColorPalette] = useState("");
  const [colorPaletteDetails, setColorPaletteDetails] = useState("");
  const [environment, setEnvironment] = useState("");
  const [environmentDetails, setEnvironmentDetails] = useState("");
  const [additionalDescriptors, setAdditionalDescriptors] = useState("");
  const [generatedPrompt, setGeneratedPrompt] = useState("");
  const [enhancedPrompt, setEnhancedPrompt] = useState("");
  const [apiKey, setApiKey] = useState(localStorage.getItem("apiKey") || "");
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("apiKey", apiKey);
  }, [apiKey]);

  const generatePrompt = () => {
    const formatSection = (value, details) => {
      if (!value || value === "Select...") {
        return details ? details : null; // Only include details if available
      }
      return `${value}${details ? `, ${details}` : ""}`;
    };

    const sections = [
      formatSection(style, styleDetails),
      formatSection(mainSubject, mainSubjectDetails),
      formatSection(actionScene, actionSceneDetails),
      formatSection(shotType, shotTypeDetails),
      formatSection(cameraAngle, cameraAngleDetails),

      formatSection(lighting, lightingDetails),
      formatSection(colorPalette, colorPaletteDetails),
      formatSection(environment, environmentDetails),
      additionalDescriptors || null,
    ].filter(Boolean); // Remove null or undefined sections

    const prompt = sections.join("\n").trim();

    setGeneratedPrompt(prompt);
  };

  const randomizeOptions = () => {
    setMainSubject(randomItem(mainSubjectOptions));
    setActionScene(randomItem(actionSceneOptions));
    setShotType(randomItem(shotTypeOptions));
    setCameraAngle(randomItem(cameraAngleOptions));
    setStyle(randomItem(styleOptions));
    setLighting(randomItem(lightingOptions));
    setColorPalette(randomItem(colorPaletteOptions));
    setEnvironment(randomItem(environmentOptions));

    // Clear additional details but do not generate the prompt automatically
    setMainSubjectDetails("");
    setActionSceneDetails("");
    setShotTypeDetails("");
    setCameraAngleDetails("");
    setStyleDetails("");
    setLightingDetails("");
    setColorPaletteDetails("");
    setEnvironmentDetails("");
    setAdditionalDescriptors("");
  };

  const copyToClipboard = () => {
    if (generatedPrompt) {
      navigator.clipboard.writeText(generatedPrompt);
      alert("Prompt copied to clipboard!");
    }
  };
  const enhancePrompt = async () => {
    if (!generatedPrompt) {
      alert("Generate a prompt first!");
      return;
    }

    if (!apiKey) {
      alert("Please provide your OpenAI API key.");
      return;
    }

    try {
      const response = await fetch("/api/enhancePrompt", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ prompt: generatedPrompt, apiKey }),
      });

      if (!response.ok) {
        throw new Error("Failed to enhance the prompt.");
      }

      const data = await response.json();
      setEnhancedPrompt(data.enhancedPrompt);
    } catch (error) {
      console.error(error);
      alert("Failed to enhance the prompt. Please try again.");
    }
  };

  const clearOptions = () => {
    setMainSubject("");
    setMainSubjectDetails("");
    setActionScene("");
    setActionSceneDetails("");
    setShotType("");
    setShotTypeDetails("");
    setCameraAngle("");
    setCameraAngleDetails("");
    setStyle("");
    setStyleDetails("");
    setLighting("");
    setLightingDetails("");
    setColorPalette("");
    setColorPaletteDetails("");
    setEnvironment("");
    setEnvironmentDetails("");
    setAdditionalDescriptors("");
    setGeneratedPrompt("");
    setEnhancedPrompt("");
  };

  const generateRandomOption = async (category, setDetails) => {
    if (!apiKey) {
      alert("Please provide your OpenAI API key.");
      return;
    }

    try {
      const response = await fetch("/api/randomOption", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ category, apiKey }),
      });

      if (!response.ok) {
        throw new Error("Failed to generate random option.");
      }

      const data = await response.json();
      setDetails(data.randomOption); // Ensure state updates
    } catch (error) {
      console.error(error);
      alert("Failed to generate random option. Please try again.");
    }
  };
  return (
    <div className="app-container2">
        <button className="roll-button2" onClick={() => navigate("/options")}>
          Go Back
        </button>
      <div className="api-key-section">
        <label>OpenAI API Key:</label>
        <input
          type="password"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
          placeholder="Enter your API key"
          className="api-key-input"
        />
      </div>

      <h2>Image Prompt Generator</h2>
      <div className="grid-container2">
        {/* Main Subject */}
        <div className="card2">
          <label>Main Subject (Who/What):</label>
          <select
            value={mainSubject}
            onChange={(e) => setMainSubject(e.target.value)}
          >
            <option value="">Select...</option>
            {mainSubjectOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <p></p>
          <textarea
            placeholder="Additional details for Main Subject..."
            value={mainSubjectDetails}
            onChange={(e) => setMainSubjectDetails(e.target.value)}
          />
          <button
            onClick={() =>
              generateRandomOption("Main Subject", setMainSubjectDetails)
            }
          >
            Generate Random Main Subject
          </button>
        </div>

        {/* Action/Scene */}
        <div className="card2">
          <label>Action/Emotion:</label>
          <select
            value={actionScene}
            onChange={(e) => setActionScene(e.target.value)}
          >
            <option value="">Select...</option>
            {actionSceneOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <p></p>
          <textarea
            placeholder="Additional details for Action/Emotion..."
            value={actionSceneDetails}
            onChange={(e) => setActionSceneDetails(e.target.value)}
          />
          <button
            onClick={() =>
              generateRandomOption("Action/Emotion", setActionSceneDetails)
            }
          >
            Generate Random Action/Emotion
          </button>
        </div>

        <div className="card2">
          <label>Shot Type/Framing:</label>
          <select
            value={shotType}
            onChange={(e) => setShotType(e.target.value)}
          >
            <option value="">Select...</option>
            {shotTypeOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <p></p>
          <textarea
            placeholder="Additional details for Shot Type..."
            value={shotTypeDetails}
            onChange={(e) => setShotTypeDetails(e.target.value)}
          />
        </div>
        <div className="card2">
          <label>Camera Angle/Perspective:</label>
          <select
            value={cameraAngle}
            onChange={(e) => setCameraAngle(e.target.value)}
          >
            <option value="">Select...</option>
            {cameraAngleOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <p></p>
          <textarea
            placeholder="Additional details for Camera Angle..."
            value={cameraAngleDetails}
            onChange={(e) => setCameraAngleDetails(e.target.value)}
          />
        </div>
        <div className="card2">
          <label>Style/Medium:</label>
          <select value={style} onChange={(e) => setStyle(e.target.value)}>
            <option value="">Select...</option>
            {styleOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <p></p>
          <textarea
            placeholder="Additional details for Style/Medium..."
            value={styleDetails}
            onChange={(e) => setStyleDetails(e.target.value)}
          />
        </div>
        <div className="card2">
          <label>Lighting & Mood:</label>
          <select
            value={lighting}
            onChange={(e) => setLighting(e.target.value)}
          >
            <option value="">Select...</option>
            {lightingOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <p></p>
          <textarea
            placeholder="Additional details for Lighting & Mood..."
            value={lightingDetails}
            onChange={(e) => setLightingDetails(e.target.value)}
          />
          <button
            onClick={() =>
              generateRandomOption("Lighting & Mood", setLightingDetails)
            }
          >
            Generate Random Lighting & Mood
          </button>
        </div>
        <div className="card2">
          <label>Color Palette & Atmosphere:</label>
          <select
            value={colorPalette}
            onChange={(e) => setColorPalette(e.target.value)}
          >
            <option value="">Select...</option>
            {colorPaletteOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <textarea
            placeholder="Additional details for Color Palette..."
            value={colorPaletteDetails}
            onChange={(e) => setColorPaletteDetails(e.target.value)}
          />
          <button
            onClick={() =>
              generateRandomOption(
                "Color Palette & Atmosphere",
                setColorPaletteDetails
              )
            }
          >
            Generate Random Color Palette & Atmosphere
          </button>
        </div>

        <div className="card2">
          <label>Environment/Background Setting:</label>
          <select
            value={environment}
            onChange={(e) => setEnvironment(e.target.value)}
          >
            <option value="">Select...</option>
            {environmentOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <p></p>
          <textarea
            placeholder="Additional details for Environment..."
            value={environmentDetails}
            onChange={(e) => setEnvironmentDetails(e.target.value)}
          />
          <button
            onClick={() =>
              generateRandomOption(
                "Environment/Background Setting",
                setEnvironmentDetails
              )
            }
          >
            Generate Random Environment/Background Setting
          </button>
        </div>
        <div className="card2">
          <label>Additional Descriptors:</label>
          <textarea
            value={additionalDescriptors}
            onChange={(e) => setAdditionalDescriptors(e.target.value)}
            placeholder="Add any extra artistic or technical details..."
          />
        </div>
      </div>
      <button onClick={clearOptions} style={{ marginBottom: "10px" }}>
        Clear Options
      </button>
      <button onClick={randomizeOptions} style={{ marginBottom: "10px" }}>
        Randomize Options
      </button>

      <p></p>
      <button onClick={generatePrompt}>Generate Prompt</button>
      {generatedPrompt && (
        <div className="generated-output2">
          <h3>Generated Prompt:</h3>
          <pre>{generatedPrompt}</pre>
          <button className="copy-button" onClick={copyToClipboard}>
            Copy Prompt
          </button>
          <button className="enhance-button" onClick={enhancePrompt}>
            Enhance Prompt
          </button>
        </div>
      )}

      {enhancedPrompt && (
        <div className="generated-output2">
          <h3>Enhanced Prompt:</h3>
          <pre>{enhancedPrompt}</pre>
          <button
            className="copy-button"
            onClick={() => {
              navigator.clipboard.writeText(enhancedPrompt);
              alert("Enhanced prompt copied to clipboard!");
            }}
          >
            Copy Enhanced Prompt
          </button>
        </div>
      )}
    </div>
  );
}
export default ImagePromptGenerator;
